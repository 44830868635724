import findMetatag from './findMetatag';

// Boot features
//    'acct-disableUserIdentityCheck' -- disable the logging of userIdentities that are not in the account source list store
//    `acct-reflowAndDefaultTelemetry` -- Indicates that the telemetry for reflow methods that should not be called is enabled
//    `acctErrStorageSetV2` -- When enabled the owa-account-storage package will throw an exception if invalid data is set
//    `acctNoFallback` -- Disables the fallback to global settings account in place of the selected account
//    `acctPersistentIdIndexerV2` -- when enabled, the indexer value comes from the persistence id of the account
//    `acctManualAccountType` -- when enabled, allows the manual selected of the type of account being added
//    `acct-autoSettingsMigrationOobe` - Flight for rolling out automatic settings migration in oobe
//    `acct-changedAccountError` - Flight for rolling out the ability to show an error when the account has changed during add account flow
//    `acct-signInWindowsAcctAutomatically` - Flight for rolling out the ability to sign in with windows account automatically
//    `acct-disablePrivacyPromptinOobe` - Flight to disable the privacy prompt in oobe as an experiment to only prompt in-app post boot
//    `mon-acct-addAccountDeeplink` -- Flight for rolling out the ability to add accounts via deeplinks in oobe
//    `fwk-consoleErrors` -- Surface console errors by showing a dialog
//    `fwk-cssPerf` -- Enables an alternate set of CSS rules that should be more performant during style recalculation
//    `fwk-persistOIDInUrl` -- Persists the OID(user unique identifier) in the root URL
//    `fwk-createstore-deffered` -- Defer the creation of the store until it is used in a scenario
//    `supportonboot` -- launches the support flow if boot fails
//    `appservicequery` -- when enabled, fetch metaOs apps from app service api
//    `accountsapi` -- when enabled the Monarch multi-account UX functionality is enabled in non monarch applications
//    `offlineWeb` -- enables offline in the browser, rather than just in monarch
//    `dev-bootOfflineFolders` -- enables offline folder resolvers
//    `dev-bootOffline` -- boot as if offline disabling service requests until user re-enables in diagnostics
//    `monPromptOnSilentFailure` -- enables NativeHost to fail on getTokenSilently and call getTokenInteractively when interaction is required from user
//    `mobxproxies` -- configures MobX to use proxies: https://mobx.js.org/configuration.html#proxy-support
//    `shellmultiorigin` -- Enable EUDB vs. WWDB domain branching for OPX shell.
//    `auth-msaljs-consumer` -- Enables the use of MSAL to get auth token for consumer accounts
//    `detectcmpfnleaks` -- Enables wrapper around computedFn to detect leaks
//    `disablecmpfn` -- Disables wrapping functions in computedFn to measure the impact
//    `jsselfprofiler` -- This flag lets us know when the JS self profiler is enabled
//    `auth-cacheTokenForMetaOsHub` -- Enable token caching for MetaOS integrations. Token caching involves decoding of bearer token to extract expiry & PUID
//    `auth-disable-onAuthFailedForMetaOsHub` -- Rip cord for teams bug fix that implments onAuthFailed to remove the owa token from the cache when a service request fails with a 401
//    `auth-useAuthTokenClaimsForMetaOsHub` -- Enable use of claims in the token to be used for auth for MetaOS integrations.
//    `cal-reload-pause` -- Reloads the calendar app when the app is cached and pauses loading of resources
//    `cal-store-NavBarData` -- Flight to retrieve NavBarData from SessionData which is used for M365 Licensing in hosted calendar
//    `auth-cloudCache-basicAuthPasswordLookup` -- Attempt to get the password for a cloud cache account using basic auth (IMAP/POP, etc.)
//    `auth-msaljs-business` -- Enables full client side authentication for business account using MSAL.js library.
//    `auth-msaljs-safari` -- Enables use of NAA in Safari using MSAL.js library.
//    `auth-msaljs-hosted` -- Enables use of NAA in MetaOS-hosted 1P modules using MSAL.js library.
//    `auth-msaljs-opx-activityfeed` -- Enables use of NAA in ActivityFeed OPX using MSAL.js library.
//    `auth-msaljs-opx-myday` -- Enables use of NAA in MyDay OPX using MSAL.js library.
//    `auth-msaljs-opx-roompanel` -- Enables use of NAA in RoomPanel OPX using MSAL.js library.
//    `auth-msaljs-newsletters` -- Enables authentication using MSAL.js library in Newsletters/Addison.
//    `auth-msaljs-places` -- Enables authentication using MSAL.js library in Places.
//    `auth-msaljs-eventify` -- Enables authentication using MSAL.js library in Eventify.
//    `auth-msaljs-bookings` -- Enables authentication using MSAL.js library in Bookings.
//    `auth-msaljs-findtime` -- Enables authentication using MSAL.js library in FindTime.
//    `auth-msaljs-meet` -- Enables authentication using MSAL.js library in Meet.
//    `auth-msaljs-disableCaeCapabilities` -- Disables CAE capabilities in MSAL.js library for local dev testing only.
//    `auth-msaljs-useDelegationToken` -- Enables the use of delegation token for MSAL consumers.
//    `auth-codeChallenge` -- Enables PKCE flow for Google cloud cache accounts.
//    `acct-mbxpkg-boot` -- used to control the usage of the owa-mailbox-package within the application
//    `auth-disableAnonymousTokenForHeader` -- Disables the use of auth tokens for anonymous mailboxInfos.
//    `fwk-loc-single-v2`  -- Use a single observable for all localized strings
//    `acct-roaming` -- Enables the roaming feature for accounts
//    `msplaces-hosted-localsessiondata-v2` -- Enables the use of local session data in hosted places
//    `msplaces-offline-sync-engine` -- Enables the use of the offline sync engine in places
//    `msplaces-app-boot-sequence` -- Enables tracing the boot steps of the Places app
//    `fwk-netcore-api ` -- Enables routing traffic from /owa/ to /owanetcore/
//    `cal-enable-HybridModel-for-calendarSharing` -- Enables the HybridModel for calendar sharing
//    `fwk-worker-script-trusted-types-policy` -- Enables the sanitize of the urls inside the worker trusted types policy
//    `fwk-netcore-update-baseurl-for-consumer-account` -- Updates the base url for consumer accounts to use the new base url
//    `cal-organizationCalendars` -- Enables the organization calendars feature
//    `fwk-getcopilot-fromstartup` -- Get the Account Copilot settings from startup aggregator cache
//    `fwk-getcopilotdeviceid-fromstartup` -- Get the Device Id Copilot settings from startup aggregator cache
//    `auth-mon-lookupUniqueIdentity2` -- Tries to prepopulate the unique identity before asking Native Host for a token
//    `fwk-decode-checkexplictlogon` -- Decode the URL part to check if this is explicit logon to handle %40 in the url instead of handling @ only
//    `fwk-enable-default-trusted-types-policy` -- Enables the default trusted types policy.

export type BootFeature =
    | 'acct-disableUserIdentityCheck'
    | 'acct-reflowAndDefaultTelemetry'
    | 'acctErrStorageSetV2'
    | 'acctNoFallback'
    | 'acctPersistentIdIndexerV2'
    | 'acctManualAccountType'
    | 'acct-autoSettingsMigrationOobe'
    | 'acct-changedAccountError'
    | 'acct-signInWindowsAcctAutomatically'
    | 'acct-disablePrivacyPromptinOobe'
    | 'mon-acct-addAccountDeeplink'
    | 'fwk-consoleErrors'
    | 'fwk-cssPerf'
    | 'fwk-persistOIDInUrl'
    | 'fwk-createstore-deffered'
    | 'supportonboot'
    | 'appservicequery'
    | 'pieUpdateIdentityAliasesMap'
    | 'accountsapi'
    | 'offlineWeb'
    | 'dev-bootOfflineFolders'
    | 'dev-bootOffline'
    | 'monPromptOnSilentFailure'
    | 'mobxproxies'
    | 'shellmultiorigin'
    | 'emergencyDisableBpos'
    | 'auth-msaljs-consumer'
    | 'detectcmpfnleaks'
    | 'disablecmpfn'
    | 'jsselfprofiler'
    | 'auth-cacheTokenForMetaOsHub'
    | 'auth-disable-onAuthFailedForMetaOsHub'
    | 'auth-useAuthTokenClaimsForMetaOsHub'
    | 'cal-reload-pause'
    | 'cal-store-NavBarData'
    | 'auth-cloudCache-basicAuthPasswordLookup'
    | 'auth-msaljs-business'
    | 'auth-msaljs-safari'
    | 'auth-msaljs-hosted'
    | 'auth-msaljs-opx-activityfeed'
    | 'auth-msaljs-opx-myday'
    | 'auth-msaljs-opx-roompanel'
    | 'auth-msaljs-newsletters'
    | 'auth-msaljs-places'
    | 'auth-msaljs-eventify'
    | 'auth-msaljs-bookings'
    | 'auth-msaljs-findtime'
    | 'auth-msaljs-meet'
    | 'auth-msaljs-disableCaeCapabilities'
    | 'auth-msaljs-useDelegationToken'
    | 'auth-msaljs-EnableLogging'
    | 'auth-codeChallenge'
    | 'auth-disableAnonymousTokenForHeader'
    | 'acct-mbxpkg-boot'
    | 'disableConcurrency'
    | 'workerAsyncLoad'
    | 'fwk-loc-single-v2'
    | 'acct-roaming'
    | 'msplaces-hosted-localsessiondata-v2'
    | 'msplaces-offline-sync-engine'
    | 'msplaces-app-boot-sequence'
    | 'fwk-netcore-api'
    | 'cal-enable-HybridModel-for-calendarSharing'
    | 'fwk-netcore-update-baseurl-for-consumer-account'
    | 'fwk-no-worker-observables'
    | 'fwk-worker-patch'
    | 'cal-organizationCalendars'
    | 'fwk-getcopilot-fromstartup'
    | 'fwk-getcopilotdeviceid-fromstartup'
    | 'auth-mon-lookupUniqueIdentity2'
    | 'fwk-decode-checkexplictlogon'
    | 'fwk-enable-default-trusted-types-policy';

// these are specifically for features that need to be decided before userbootsettings comes back.
let bootFlights: string[] | undefined;
let bootFlightsLowercase: Set<string> | undefined;
let bootFlightsWithAllocationIds: string[] | undefined;
export function isBootFeatureEnabled(feature: BootFeature) {
    return getBootFlightsLowercase().has(feature.toLowerCase());
}

export function getBootFlights(): string[] {
    if (!bootFlights) {
        const meta = findMetatag('bootFlights');
        bootFlights = meta ? meta.split(',').map(m => m.split(':')[0]) : [];
    }
    return bootFlights;
}

export function getBootFlightsWithAllocationIds(): string[] {
    if (!bootFlightsWithAllocationIds) {
        const meta = findMetatag('bootFlights');

        bootFlightsWithAllocationIds = meta ? meta.split(',') : [];
    }
    return bootFlightsWithAllocationIds;
}

export function test_reset() {
    bootFlights = undefined;
    bootFlightsLowercase = undefined;
}

function getBootFlightsLowercase(): Set<string> {
    if (!bootFlightsLowercase) {
        bootFlightsLowercase = new Set(getBootFlights().map(key => key.toLowerCase()));
    }
    return bootFlightsLowercase;
}
